@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;600;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Outfit', sans-serif;
}

:root{
  --bs-link-color: #00C170 !important;
  --bs-link-color-disabled: #00C17080 !important;
  --bs-link-hover-color : var(--bs-link-color) !important;
}

::-webkit-scrollbar {
  width: 6px;
  padding: 5px 0;
}

::-webkit-scrollbar-thumb {
  background: #00C170; 
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #00C17080; 
}

.btn-light {
  --bs-btn-color: #005E1A !important;
  --bs-btn-hover-color: #005E1A !important;
  --bs-btn-active-color: #005E1A !important;
}

.btn-primary {
  --bs-btn-bg: #00C170 !important;
  --bs-btn-border-color: #00C170 !important;
  --bs-btn-hover-bg: #00A150 !important;
  --bs-btn-hover-border-color: #00A150 !important;
  --bs-btn-active-bg: #00A150 !important;
  --bs-btn-active-border-color: #00A150 !important;
  --bs-btn-disabled-bg: #00C170 !important;
  --bs-btn-disabled-border-color: #00C170 !important;
}

.bg-primary {
  --bs-primary-rgb: 0, 193, 112;
}

.btn-outline-primary {
  --color: var(--app);
  --bs-btn-color: var(--color) !important;
  --bs-btn-border-color: var(--color) !important;
  --bs-btn-hover-bg: var(--color) !important;
  --bs-btn-hover-border-color: var(--color) !important;
  --bs-btn-active-bg: var(--color) !important;
  --bs-btn-active-border-color: var(--color) !important;
  --bs-btn-disabled-color: var(--color) !important;
  --bs-btn-disabled-border-color: var(--color) !important;
}

.accordion {
  --bs-accordion-border-color: #fff !important;
  --bs-accordion-btn-icon: url('https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/plus-512.png') !important;
  --bs-accordion-btn-active-icon: url('https://cdn.iconscout.com/icon/free/png-256/minus-146-475070.png') !important;
  --bs-accordion-active-color: #000 !important;
  --bs-accordion-active-bg: #fff !important;
}

body.fix {
  height: 100dvh;
  overflow: hidden;
}


html, body {
  overflow-x: hidden;
  width: 100dvw;
  -webkit-user-select: none;
  -ms-user-select: none; 
  user-select: none;
}

.bg-glass-w, .bg-glass-g, .bg-glass-b {
  --bs-card-bg: none;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(10px) !important;
  border: none;
}

.bg-glass-w {
  background-image: linear-gradient(-45deg, #fff5, #aaa5) !important;
}

.bg-glass-b {
  background-image: linear-gradient(-45deg, #0005, #5555) !important;
}

.bg-glass-g {
  background-image: linear-gradient(-45deg, #D6EEE0, #0A05) !important;
}

.cursor-pointer{
  cursor: pointer;
}

h6::first-letter, .h1::first-letter {
  text-transform: capitalize;
}

.fs-7 {
  font-size: .7rem !important;
}

.text-justify {
  text-align: justify;
  /* text-justify: inter-word; */
}

.navbar-toggler:focus, .form-control:focus, .form-check-input:focus, .accordion-button:focus, .page-link:focus, .btn-close:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #CED4DA !important;
}  

#root > .container-fluid {
  position: relative;
  min-height: 100dvh;
  padding-top: 20px;
}

body.modal-open {
  height: 100dvh;
}

span {
  color: #00C170;
}

p {
  text-align: justify;
}

.video-container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100dvh;
  width: 100dvw;
  background: #0005;
  backdrop-filter: blur(80px);
}

.bg-video {
  width: 100%;
  height: var(--height);
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.card-body .col-2, .card-body .col-3 {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.card {
  background-position: center;
  background-size: cover;
  border: none !important;
}

.card-rounded {
  border-radius: 1.5rem !important;
}
.card-rounded .card-img-top {
  border-radius: 1.5rem 1.5rem 0 0 !important;
  max-height: 200px;
  object-fit: cover;
} 

.header-center {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.2rem;
  align-items: center;
} 

.header-center::before,
.header-center::after {
  content: "";
  display: flex;
  height: 1px;
  background-color: #5f5f5f;
}

.form-check-input:checked {
  background-color: #00C170 !important;
  border-color: #00C170 !important;
}

.fade.modal.show {
  backdrop-filter: blur(5px);
}

#why-choose-us-img {
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#faq, #support {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

button.accordion-button {
  padding-top: 8px;
  padding-bottom: 8px;
}

#support {
  background-position: bottom;
}

.leaflet-container {
  width: 100%;
  height: 150px;
  z-index: 1;
}

@media (max-width: 576px) { 
  #support {
    min-height: 150dvh !important;
  }
}